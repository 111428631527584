:root {
    font-size: 16px;
}

@import '../node_modules/ngx-toastr/toastr.css';
//@import '../node_modules/@ng-select/ng-select/themes/default.theme.css'; //scss doesnt seem to exist ?
//    @import '../node_modules/@ng-select/ng-select/scss/default.theme.scss'
//@import '../node_modules/angular-calendar/css/angular-calendar.css';

.rezbot-calendar-bookit {
    input {
        background-color: transparent !important;
        margin: 0 !important;
        height: auto !important;
        padding: 0 !important;
    }

    @import '../node_modules/angular-calendar/scss/angular-calendar.scss';
    //@import '../node_modules/@ng-select/ng-select/scss/default.theme.scss';
    //@import '../node_modules/scss/angular-calendar.scss';
    @import '../node_modules/@ng-select/ng-select/scss/default.theme.scss'; //scss doesnt seem to exist ?
    @import 'tailwind.scss';

    .cal-month-view {
        background: transparent !important;
    
        /*.cal-day-cell {
            &.closed {
                pointer-events: none;
            }
    
            &:not(.closed) > div > .month-day {
                cursor: pointer
            }
        }*/
    
        .cal-days {
            border: none;
    
            .cal-cell-row {
                border: none;
            }
        }
    
        .available {
            .gn-month-day, .gn-session {
                background-color: #669900;
            }
        }
    
        .closed {
            .gn-month-day, .gn-session {
                background-color: #cc0000;
                opacity: 0.6;
            }
        }
    
        .limited {
            .gn-month-day, .gn-session {
                background-color: #ffaa00;
            }
        }
    
    
        .cal-day-cell {
            min-height: auto;
    
            &:not(:last-child) {
                border-right: none;
            }
    
            &.cal-today {
                background: none;
            }
        }
    
        .cal-cell-row {
            @extend .gn-flex !optional;
            @extend .gn-items-center !optional;
            @extend .gn-justify-center !optional;
            
            &:hover {
                background: none;
            }
    
            .cal-cell {
                &:hover {
                    background: none;
                }
            }
        }
    }
}

geronigo-bookit, rezbot-bookit {
    max-height: 100%;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    * {
        backface-visibility: visible;
        background: 0;
        border: none;
        box-shadow: none;
        caption-side: top;
        clear: none;
        float: none;
        font-style: inherit;
        font-variant: inherit;
        font-weight: inherit;
        font-size: 1rem;
        text-transform: inherit;
        height: auto;
        letter-spacing: normal;
        line-height: normal;
        list-style: none;
        max-height: none;
        max-width: none;
        min-height: auto;
        min-width: auto;
        opacity: 1;
        overflow: visible;
        position: static;
        text-align: inherit;
        text-align-last: auto;
        text-decoration: none;
        text-decoration-color: inherit;
        text-indent: 0;
        text-shadow: none;
        transform: none;
        transition: none;
        vertical-align: baseline;
        visibility: visible;
        white-space: normal;
        width: auto;
        word-spacing: normal;
        z-index: auto;

        box-sizing: border-box;
        margin: 0;
        padding: 0;
        background-color: transparent;
        // scrollbar-width: none;
    }

    fa-icon {
        display: inline-block;
    }
    
    .ng-select {
        font-size: 1rem !important;
    
        .ng-select-container {
            height: 42px !important;
        }
    }
    
    .gn-flex-1 {
        flex: 1 0;
    }
    
    .gn-spin {
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .invalid {
        border-color: #cc0000;
    }
    
    button {
        .gn-hint {
            display: none;
        }
    }
    
    .gn-lock {
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
    }
    
    input:disabled {
        color: rgba($color: #000000, $alpha: 0.5)
    }
    
    button[disabled] {
        background: #e1e1e1 !important;
        cursor: initial;
        color: #b4b4b4 !important;
    
        &:hover {
            background: #e1e1e1 !important;
            opacity: 1 !important;
    
            & > .gn-hint {
                display: block;
            }
    
            & > .gn-text {
                display: none;
            }
        }
    }

    input[type="checkbox"].ng-invalid.ng-touched + span {
        color: #c53030;
    }

    input.ng-invalid.ng-touched{
        color: #c53030 !important;
    }

    mwl-calendar-month-cell {
        background: transparent !important;
        @extend .gn-flex !optional;
        @extend .gn-items-center !optional;
        @extend .gn-justify-center !optional;
        @extend .gn-w-full !optional;
    }
    
    @keyframes spin {
        from {
            transform: rotate(0deg);
        } to {
            transform: rotate(360deg);
        }
    }
    
    input:required::placeholder {
        color: #cc0000;
    }
    
    .gn-spinner-loader {
        margin: 0 5px;
        text-align: center;
    
        & > div {
            width: 5px;
            height: 5px;
            background-color: #2d3748;
            vertical-align: middle;
            border-radius: 100%;
            display: inline-block;
            animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        }
    
        &.gn-spinner-inverse > div {
            background-color: #ffffff;
        }
        
        .gn-bounce2, .gn-bounce3 {
            margin-left: 2px;
        }
        
        & .gn-bounce1 {
            animation-delay: -0.32s;
        }
        
        & .gn-bounce2 {
            animation-delay: -0.16s;
        }
    }
    
    .gn-age-restriction {
        color: #ffffff;
        font-weight: bold;
        font-size: 0.8rem;
        border-radius: 50%;
        background: #68a49d;
        border: 1px solid #5b9790;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        width: 2rem;
    }
    
    .shake {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
    
    @keyframes sk-bouncedelay {
        0%, 80%, 100% {
            transform: scale(0)
        } 40% {
            transform: scale(1.0)
        }
    }
    
    @keyframes shake {
        10%, 90% {
          transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
          transform: translate3d(2px, 0, 0);
        }
      
        30%, 50%, 70% {
          transform: translate3d(-4px, 0, 0);
        }
      
        40%, 60% {
          transform: translate3d(4px, 0, 0);
        }
    }
}



tooltip {
    display:block;
    min-width:300px;
    border: solid 1px #4a5568;
}

.note-tooltip{
    background-color: #ffffff !important;
    color: #4a5568 !important;
}

.booking-tooltip {
    background-color: #ffffff !important;
    color: #4a5568 !important;

    &.tooltip-right:after {
        border-color: transparent #fff transparent transparent !important;
    }

    &.tooltip-top:after {
        border-color: #fff transparent transparent transparent !important;
    }
}
